<template>    
  <div class="background_basic">            
    <v-card class="ma-3">
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          <v-icon class="mr-2">mdi-access-point</v-icon>AP
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">refresh</v-icon>초기화
        </v-btn>
        <v-btn color="blue" text @click="ExcelExpert('AP')" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn color="blue" text @click="uploadExcel()" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-cloud-upload-outline</v-icon>엑셀업로드
        </v-btn>

        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="blue" text v-on="on" :disabled="!ProgramPermissionInfo.okC" @click="ClearDialog()" class="font-weight-bold text-subtitle-1">
              <v-icon big class="mr-2">post_add</v-icon>신규등록
            </v-btn>
          </template>
          <v-card ref="form">
            <v-card-title >
              <span class="font-weight-bold">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="ma-0">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field dense v-model="editedItem.cApId" label="Id" :disabled="isEditMode" maxlength="20" hint="필수항목" persistent-hint></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field dense v-model="editedItem.cMac" label="Mac" maxlength="50" hint="필수항목" persistent-hint></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select  dense v-model="editedItem.cType" label="장비타입" :items="zDeviceType" item-value="sId" item-text="sNm"></v-select> 
                  </v-col>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field dense v-model="editedItem.cIp" label="Ip address" maxlength="50" hint="필수항목" persistent-hint append-icon="mdi-dots-horizontal-circle" @click:append="onIPSetSend(editedItem)"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field dense v-model="editedItem.cPort" label="Port" maxlength="10" hint="필수항목" persistent-hint></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select dense v-model="editedItem.cInOut" label="InOut 정보" :items="zApInOutType" item-value="sId" item-text="sNm"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field dense v-model="editedItem.cServerIp" label="Server Ip address" maxlength="50" hint="필수항목" persistent-hint append-icon="mdi-dots-horizontal-circle" @click:append="onServerIPSetSend(editedItem)"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field dense v-model="editedItem.cPowerId" label="Power Id" ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field dense v-model="editedItem.iLimitRSSI" label="Limit RSSI" type="Number"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" >
                    <v-text-field dense v-model="editedItem.iCounterFillter" label="Counter Fillter"  type="Number"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-select dense v-model="editedItem.cUse" label="사용여부" :items="zUse"  item-value="sId" item-text="sNm"></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>                
              <v-spacer></v-spacer>
              <v-btn color="blue darken-2" text @click="save" :disabled="loading" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">save</v-icon>저장
              </v-btn>
              <v-btn color="grey darken-2" text @click="close" :disabled="loading" class="font-weight-bold text-subtitle-1">
                <v-icon big class="mr-2">cancel</v-icon>취소
              </v-btn>
            </v-card-actions>              
            <v-progress-linear :active="loading" :indeterminate="loading" absolute bottom color="blue accent-4"></v-progress-linear>
          </v-card>
        </v-dialog>
      </v-toolbar>      

      <v-divider></v-divider>
    
      <v-card flat class="d-flex ml-3 mr-3">                
        <v-card class="ml-3 mb-1" flat style="max-width:150px;">   
          <v-select v-model="cNowCheck" class="mb-n5" label="배정여부" :items="zMappingAll" item-value="sId" item-text="sNm"></v-select>   
        </v-card>           
        <v-text-field
          v-model="cNowSearchKeyWord"
          class="ml-3 mb-n5"
          label="검색어"
          placeholder="Id or Mac 입력"
          persistent-placeholder
          style="max-width:250px;"
          Regular
          v-on:keyup.enter="Search()"
        ></v-text-field>
        
        <v-btn icon class="ml-n8 mt-3 text-subtitle-1" @click="Search()">
          <v-icon>search</v-icon>
        </v-btn>
      </v-card>    
      <v-divider></v-divider>

      <v-data-table         
        :items-per-page="15" 
        fixed-header 
        height="calc(100vh - 247px)" 
        :headers="headers" 
        :items="Records"
        @dblclick:row="handleDoubleClick">
        <template v-slot:item.action="{ item }">          
          <v-icon big class="mr-0" @click="editItem(item)" :disabled="!ProgramPermissionInfo.okU">edit</v-icon>
          <v-icon big @click="deleteItem(item)" :disabled="!ProgramPermissionInfo.okD">delete</v-icon>          
          
          <v-tooltip top>                                
            <template v-slot:activator="{ on }">                 
              <v-icon v-on="on" big @click="resetItem(item)" :disabled="!ProgramPermissionInfo.okU">mdi-refresh</v-icon>    
            </template>                    
            Reset now.
          </v-tooltip>                  
          <v-tooltip top>                                
            <template v-slot:activator="{ on }">   
              <v-icon v-on="on" big @click="onSettingApInfo(item)" :disabled="!ProgramPermissionInfo.okU">mdi-send</v-icon>                                        
            </template>                    
            Apply now.
          </v-tooltip>                  
        </template>

        <template v-slot:item.cMapId="{ item }">
          <v-chip v-if="(item.cMapId == 'NN') " color="red lighten-2" small label dark @click="goApInfoPage()">미배정
            <v-icon big class="ml-1 mr-n1">mdi-chevron-right</v-icon>
          </v-chip>
          <v-chip v-else small label color="grey lighten-3" @click="goApInfoPage()">배정
            <v-icon big class="ml-1 mr-n1">mdi-chevron-right</v-icon>
          </v-chip>
        </template> 

        <template v-slot:item.cUse="{ item }">
          <v-chip v-if="item.cUse == 'Y' " small label color="grey lighten-3">사용</v-chip>
          <v-chip v-else small color="grey darken-3" label dark>미사용</v-chip>
        </template> 

        <template v-slot:item.cState="{ item }">
          <v-chip v-if="item.cState == 'ON' " small label color="grey lighten-3">ON</v-chip>
          <v-chip v-else-if="item.cState == 'OFF' " small label color="red lighten-2" dark>OFF</v-chip>
          <v-chip v-else-if="item.cState == 'ERR' " small label color="orange lighten-2" dark>Error</v-chip>
          <v-chip v-else small color="grey darken-3" label dark>{{item.cState}}</v-chip>
        </template> 

        <template v-slot:item.cInOut="{ item }">
          <v-chip v-if="item.cInOut == 'NONE' " small label color="grey lighten-3">일반</v-chip>
          <v-chip v-if="item.cInOut == 'IN' " small label color="red lighten-2" dark>IN</v-chip>
          <v-chip v-if="item.cInOut == 'OUT' " small color="blue lighten-2" label dark>OUT</v-chip>
        </template>     
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>
    </v-card>
  
    <MsgBox ref="MsgBoxRef" @onMsgBox="onMsgBoxFunc" />    
    <MsgBox ref="MsgBoxRef2" @onMsgBox="onMsgBoxFunc2" />    
    <MsgBox ref="MsgBoxRef3" @onMsgBox="onMsgBoxFunc3" />    
    <MsgBox ref="MsgBoxRef4" @onMsgBox="onMsgBoxFunc4" />    
    <UploadExcel ref="UploadExcelRef" @onReadFile="onReadFile"/> 

    <v-dialog max-width="2048px" v-model="bReadFile">
      <v-card >              
        <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon>mdi-cloud-upload-outline</v-icon>
            엑셀 업로드
          </v-toolbar-title>          
          <v-divider class="mx-4" inset vertical></v-divider>                    
        </v-toolbar>
        <v-data-table 
          :items-per-page="-1" 
          fixed-header 
          height="calc(100vh - 300px)" 
          :headers="Fileheaders" 
          :items="FileRecords"          
          >                  
          <template v-slot:item.cUse="{ item }">
            <v-chip v-if="item.cUse == 'Y'" color="grey lighten-3" label small>사용</v-chip>
            <v-chip v-if="item.cUse == 'N'" color="red darken-4" label small dark>중지</v-chip>
          </template>
          <template v-slot:item.cLocked="{ item }">
            <v-chip v-if="item.cLocked == 'Y'" color="red darken-3" label small dark>잠금</v-chip>
            <v-chip v-if="item.cLocked == 'N'" color="grey lighten-4" label small>정상</v-chip>
          </template>
          <template v-slot:no-data>
            <p>No data available.</p>
          </template>
  
        </v-data-table>

        <v-divider></v-divider>
        <v-progress-linear :active="loading2" :indeterminate="loading2" absolute bottom color="blue accent-4"></v-progress-linear>
        <v-card-actions>
          <v-spacer></v-spacer> 
          <v-btn color="black darken-1" text @click="uploadExcelFile()" :disabled="FileRecords.length == 0"><v-icon class="mr-2">mdi-upload</v-icon>확인</v-btn>        
          <v-btn color="black darken-1" text @click="bReadFile=false"><v-icon class="mr-2">mdi-close</v-icon>닫기</v-btn>        
        </v-card-actions>
      </v-card>    
    </v-dialog>      
  </div>
</template>

<script>
import MsgBox from "../components/MsgBox.vue";
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios";
import { mapState } from "vuex";
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';
import router from "../router";
import UploadExcel from "@/components/UploadExcel.vue";  


export default {
  components: {
    MsgBox,
    UploadExcel,    
  },
  data: () => ({
    bReadFile : false,
    cNowSearchKeyWord : "",
    cNowCheck: "A",
    loading : false,
    loading2 : false,
    isEditMode : false,
    iDelIndex: 0,
    dialog: false,    
    FileRecords : [],
    Fileheaders: [      
      { class: "font-weight-bold subtitle-2", text: "Id", value: "cId", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "Mac", value: "cMac", align: "center"},
      { class: "font-weight-bold subtitle-2", text: "Type", value: "cType", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "IP", value: "cIp", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "Port", value: "cPort", align: "center",},
      { class: "font-weight-bold subtitle-2", text: "InOut", value: "cInOut", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "Server", value: "cServer", align: "center",},
      { class: "font-weight-bold subtitle-2", text: "PowerId", value: "cPowerId", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "LimitRSSI", value: "cLimitRSSI", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "Count", value: "cCount", align: "center",},
      { class: "font-weight-bold subtitle-2", text: "사용여부", value: "cUse", align: "center",},
    ],  
    headers: [      
      { class: "font-weight-bold subtitle-2", text: "Id", value: "cApId", align: "center" },      
      { class: "font-weight-bold subtitle-2", text: "Mac", value: "cMac", align: "center"},
      { class: "font-weight-bold subtitle-2", text: "위치", value: "cMapFullNm", align: "center", width:150},
      // { class: "font-weight-bold subtitle-2", text: "Type", value: "cType", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "State", value: "cState", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "작업", value: "action", align: "center", sortable: false, width:130},
      { class: "font-weight-bold subtitle-2", text: "IP", value: "cIp", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "Port", value: "cPort", align: "center",},
      { class: "font-weight-bold subtitle-2", text: "InOut", value: "cInOut", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "Server", value: "cServerIp", align: "center",},
      { class: "font-weight-bold subtitle-2", text: "PowerId", value: "cPowerId", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "LimitRSSI", value: "iLimitRSSI", align: "center", },
      { class: "font-weight-bold subtitle-2", text: "Count", value: "iCounterFillter", align: "center",},
      { class: "font-weight-bold subtitle-2", text: "배정여부", value: "cMapId", align: "center" },
      { class: "font-weight-bold subtitle-2", text: "등록일", value: "cCreateDateTime", align: "center", width:150},
      { class: "font-weight-bold subtitle-2", text: "사용여부", value: "cUse", align: "center" },      
      
    ],
    Records: [],
    editedIndex: -1,
    // userId(스토어), cDel(golang), cEtc(golang)만 제외하고 테이블의 항목을 설정함 
    editedItem: { 
      cApId: "",
      cMapTreeId: "NONE",
      cIp: "",
      cPort: "45",      
      x: "0",
      y: "0",
      cState: "OFF",
      cType: "BLE",
      cPosition: "NONE",
      cInOut: "NONE",
      cMac: "",
      cServerIp: "192.168.0.1",
      cPowerId : "",
      iLimitRSSI :"-90",
      iCounterFillter : "5",
      cUse: "Y",      
      cDel: "N",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    deletedItem: {
      cApId: "",
      cMapTreeId: "NONE",
      cIp: "",
      cPort: "45",
      x: "0",
      y: "0",
      cState: "OFF",
      cType: "BLE",
      cPosition: "NONE",
      cInOut: "NONE",
      cMac: "",
      cServerIp: "192.168.0.1",
      cPowerId : "",
      iLimitRSSI :"-90",
      iCounterFillter : "5",
      cUse: "Y",      
      cDel: "N",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    },
    defaultItem: {
      cApId: "",
      cMapTreeId: "NONE",      
      cIp: "",
      cPort: "45",
      x: "0",
      y: "0",
      cState: "OFF",
      cType: "BLE",
      cPosition: "NONE",
      cInOut: "NONE",
      cMac: "",
      cServerIp: "192.168.0.1",
      cPowerId : "",
      iLimitRSSI :"-90",
      iCounterFillter : "5",
      cUse: "Y",      
      cDel: "N",
      cCreateDateTime: "",
      cUpdateDateTime: "",
    }
  }),

  computed: {
    ...mapState(["UserInfo", "ProgramPermissionInfo", "zUse", "zMappingAll", "zDeviceType", "zApState", "zApInOutType"]),
    formTitle() {
      return this.editedIndex === -1 ? "신규 등록" : "항목 수정";
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {    
    onSettingApInfo(data){
      var posData = {        
        cApId: data.cApId,        
        cApType: data.cInOut,        
        cLimitRSSI: data.iLimitRSSI,        
        cCounterFillter: data.iCounterFillter,        
      }

      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SettingApInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {                        
            EventBus.$emit("onShowMsg",false, "전송을 완료했습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },
    onIPSetSend(data){
      this.iDelIndex = this.Records.indexOf(data);
      this.deletedItem = Object.assign({}, data);
      this.$refs.MsgBoxRef4.RunMsgBoxShow(
        "확인",
        "아이피를 바꾸시겠습니까? \r\n [" +
          this.deletedItem.cApId + "/" + this.deletedItem.cIp +
          "]"
      );
    },

    onMsgBoxFunc4(result){      
      if (!result) return;
      var posData = {        
        cApId: this.deletedItem.cApId,        
        cIp: this.deletedItem.cIp,        
      }

      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SendApIp", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {                        
            EventBus.$emit("onShowMsg",false, "전송을 완료했습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },

      
    onServerIPSetSend(data){
      this.iDelIndex = this.Records.indexOf(data);
      this.deletedItem = Object.assign({}, data);
      this.$refs.MsgBoxRef3.RunMsgBoxShow(
        "확인",
        "서버 아이피를 바꾸시겠습니까? \r\n [" +
          this.deletedItem.cApId + "/" + this.deletedItem.cServerIp +
          "]"
      );

    },

    onMsgBoxFunc3: function(result) {
      if (!result) return;

      var posData = {        
        cApId: this.deletedItem.cApId,        
        cServerIp: this.deletedItem.cServerIp,        
      }

      console.log(this.deletedItem.cServerIp);

      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SendApServerIp", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {                        
            EventBus.$emit("onShowMsg",false, "전송을 완료했습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },

    initialize() {
      this.cNowSearchKeyWord = "";
      this.cNowCheck= "A",
      this.Search();    
    },
    

    ClearDialog() {      
      this.isEditMode = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;                      
    },


    goApInfoPage(){
      router.push({name: "APInfo"});
    },

    handleDoubleClick(value, items) { 
       if (!this.ProgramPermissionInfo.okU) return;
       this.editItem(items.item);  
    },

    Search() {
      this.Records = [];
      var posData = {
        cStartDatetime: this.sStartDate + " 00:00:00",  
        cEndDatetime  : this.sEndDate + " 23:59:59",
        cNowCheck: this.cNowCheck,
        cNowSearchKeyWord : this.cNowSearchKeyWord,
      };
      axios
        .post(BasicInfo.UIL_API + "GetApInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
        });
    },

    save() {
      if (this.editedItem.cApId == "" ) {
        EventBus.$emit("onShowMsg",true, "ID는 필수입력 항목입니다.");
        return;
      }

      if (this.editedItem.cMac == "" ) {
        EventBus.$emit("onShowMsg",true, "MAC은 필수입력 항목입니다.");
        return;
      }

      if (this.editedItem.cIp == "" ) {
        EventBus.$emit("onShowMsg",true, "IP는 필수입력 항목입니다.");
        return;
      }
      if (this.editedItem.cPort == "" ) {
        EventBus.$emit("onShowMsg",true, "Port는 필수입력 항목입니다.");
        return;
      }

      if (this.editedItem.cServerIp == "" ) {
        EventBus.$emit("onShowMsg",true, "Server Ip 필수입력 항목입니다.");
        return;
      }
      if (this.editedItem.iLimitRSSI == "" ) {
        EventBus.$emit("onShowMsg",true, "Limit RSSI 필수입력 항목입니다.");
        return;
      }

      
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,      
        cApId: this.editedItem.cApId,
        cNewApId: "",
        cEditApId: "",        
        cIp: this.editedItem.cIp,
        cPort: this.editedItem.cPort,
        x: parseFloat(this.editedItem.x),
        y: parseFloat(this.editedItem.y),
        cState: this.editedItem.cState,
        cType: this.editedItem.cType,
        cPosition: this.editedItem.cPosition,
        cInOut: this.editedItem.cInOut,
        cMac: this.editedItem.cMac,                
        cUse: this.editedItem.cUse,
        iLimitRSSI: this.editedItem.iLimitRSSI,
        iCounterFillter: this.editedItem.iCounterFillter,
        cServerIp: this.editedItem.cServerIp,
        cPowerId: this.editedItem.cPowerId,
        cDel: this.editedItem.cDel,
        }

      if (this.isEditMode) {
        posData.cEditApId = this.editedItem.cApId;
      } else {
        posData.cNewApId = this.editedItem.cApId;
      }
      
      
      
      this.loading = true;
      axios
        .post(BasicInfo.UIL_API + "SetApInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            if (this.isEditMode) {
              this.editedItem.cApId = res.data.Result[0].cApId;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              Object.assign(this.Records[this.editedIndex], this.editedItem);
            } else {
              this.editedItem.cApId = res.data.Result[0].cApId;
              this.editedItem.cCreateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.cUpdateDateTime = res.data.Result[0].cUpdateDateTime;
              this.editedItem.sAssign = "매핑해제"
              this.editedItem.id = this.Records.length +1;     // 자동카운트시
              this.Records.push(this.editedItem);
            }
            this.dialog = false;
            EventBus.$emit("onShowMsg",false, "저장되었습니다.");
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;
        });
    },
    
    
    close() {      
      this.dialog = false;
      this.isEditMode = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    
    editItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);   
      this.editedItem = Object.assign({}, item);       
      this.dialog = true;
    },

    
    deleteItem(item) {
      this.iDelIndex = this.Records.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.$refs.MsgBoxRef.RunMsgBoxShow(
        "확인",
        "삭제하시겠습니까? \r\n 삭제하시면 복원되지 않습니다. \r\n[" +
          this.deletedItem.cApId +
          "]"
      );
    },

    
    onMsgBoxFunc: function(result) {
      if (result === true) {
        //삭제
        var posData = {
          cUserId: this.$store.state.UserInfo.UserId,
          cApId: this.deletedItem.cApId,
        };
        axios
          .post(BasicInfo.UIL_API + "DelApInfo", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
            if (res.data.ResultCd === "00") {
              this.Records.splice(this.iDelIndex, 1); 
              EventBus.$emit("onShowMsg",false, "삭제되었습니다.");
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },


    resetItem(item) {
      this.iDelIndex = this.Records.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.$refs.MsgBoxRef2.RunMsgBoxShow(
        "확인",
        "장비를 리셋하시겠습니까? \r\n [" +
          this.deletedItem.cApId +
          "]"
      );
    },

    //Device Reset
    onMsgBoxFunc2: function(result) {
      if (result === true) {        
        var posData = {
          cUserId: this.$store.state.UserInfo.UserId,
          cApId: this.deletedItem.cApId,
        };
        axios
          .post(BasicInfo.UIL_API + "ResetApInfo", posData, {
            headers: {
              Authorization: this.$store.state.UserInfo.Token
            }
          })
          .then(res => {
            if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
            if (res.data.ResultCd === "00") {
              EventBus.$emit("onShowMsg",false, "성공적으로 명령을 전송했습니다.");
            } else {
              EventBus.$emit("onShowMsg",true, res.data.Msg);
            }
          })
          .catch(err => {
            EventBus.$emit("onShowMsg",true, ""+err);
          });
      }
    },
    
    
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },

    uploadExcel(){
      this.$refs.UploadExcelRef.Show();
    },

    onReadFile(data){
      this.FileRecords = [];
      if (data.length == 0) {
        return
      }

      for (let i = 0; i < data.length; i++) {
        var a = {
          cId : String(data[i].Id),
          cMac : String(data[i].Mac),
          cType : String(data[i].Type),          
          cIp : String(data[i].IP),
          cPort : String(data[i].Port),
          cInOut : String(data[i].InOut),
          cServer : String(data[i].Server),
          cPowerId : String(data[i].PowerId),
          cLimitRSSI : String(data[i].LimitRSSI),
          cCount : String(data[i].Count),          
          cUse : String(data[i].사용여부),
        }

        if (a.cUse == "") {
          a.cUse = "Y";
        }
        this.FileRecords.push(a);
      }      
      this.bReadFile = true;
    }, 

    uploadExcelFile(){      
      var posData = {
        cUserId: this.$store.state.UserInfo.UserId,
        ZData : this.FileRecords,        
      }      
      this.loading2 = true;
      
      axios
        .post(BasicInfo.UIL_API + "UploadExcelApInfo", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {          
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            EventBus.$emit("onShowMsg",false, "저장했습니다.");
            this.bReadFile = false;
            
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading2 = false;
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading2 = false;          
        });
    },  
  }
};
</script>

